export const fieldLimits = {
    // length of string
    username: {
        min: 3,
        max: 32
    },
    // length of string
    email: {
        max: 128
    },
    // length of string
    password: {
        min: 8,
        max: 100
    },
    server_form: {
        // length of string
        title: {
            min: 3,
            max: 128
        },
        // length of string
        ip: {
            min: 5,
            max: 128
        },
        // number
        port: {
            min: 1,
            max: 65535
        },
        // length of string
        description: {
            min: 3,
            max: 4096
        },
        // size of array
        tags: {
            max: 100,
            // length of string
            tag: {
                min: 2,
                max: 128
            }
        },
        // length of string
        website_url: {
            max: 256
        },
        votifier: {
            // length of string
            ip: {
                max: 128
            },
            // number
            port: {
                max: 65535
            },
            // length of string
            token: {
                max: 2048
            }
        }
    },
    contact: {
        name: {
            min: 3,
            max: 128
        },
        subject: {
            min: 5,
            max: 256
        },
        message: {
            min: 10,
            max: 4096
        },
    }
}
